@import "../../../styles/var";

.tableContainer {
  @media (max-width: $md4+px) {
    overflow-x: visible;
    position: relative;
    z-index: 30;
  }
}

.tableWrap {
  margin-top: 120px;
  @media (max-width: $md3+px) {
    overflow-x: auto;
    overflow-y: hidden;
  }
  @media (max-width: $md4+px) {
    margin-top: 50px;
  }
  @media (max-width: $md5+px) {
    margin-top: 50px;
  }
  .gridWrap {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 0.2fr 1fr 1fr 1fr 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "grid1 grid2 grid3 grid4"
      "grid5 grid6 grid7 grid8"
      "grid9 grid10 grid11 grid12"
      "grid13 grid15 grid16 grid17"
      "grid18 grid19 grid20 grid21"
      "grid22 grid23 grid24 grid25";

    @media (max-width: $md2+px) {
      width: 1352px;
      margin-bottom: 10px;
    }
    @media (max-width: $md2+px) {
      width: 1250px;
    }
    @media (max-width: $md5+px) {
      width: 920px;
    }

    .grid1 {
      grid-area: grid1;
    }

    .grid2 {
      grid-area: grid2;
    }

    .grid3 {
      grid-area: grid3;
    }

    .grid4 {
      grid-area: grid4;
    }

    .grid5 {
      grid-area: grid5;
    }

    .grid6 {
      grid-area: grid6;
    }

    .grid7 {
      grid-area: grid7;
    }

    .grid8 {
      grid-area: grid8;
    }

    .grid9 {
      grid-area: grid9;
    }

    .grid10 {
      grid-area: grid10;
    }

    .grid11 {
      grid-area: grid11;
    }

    .grid12 {
      grid-area: grid12;
    }

    .grid13 {
      grid-area: grid13;
    }

    .grid15 {
      grid-area: grid15;
    }

    .grid16 {
      grid-area: grid16;
    }

    .grid17 {
      grid-area: grid17;
    }

    .grid18 {
      grid-area: grid18;
    }

    .grid19 {
      grid-area: grid19;
    }

    .grid20 {
      grid-area: grid20;
    }

    .grid21 {
      grid-area: grid21;
    }

    .grid22 {
      grid-area: grid22;
    }

    .grid23 {
      grid-area: grid23;
    }

    .grid24 {
      grid-area: grid24;
    }

    .grid25 {
      grid-area: grid25;
    }
    .tableCol {
      display: flex;
      align-items: center;
      font-size: 20px;
      padding: 30px 25px;
      border-left: 2px solid #a5adc5;
      &.tableBorderNone {
        border-left: none;
      }
      &.bgGrey {
        background: linear-gradient(0deg, #c4cce0, #c4cce0), #c3cfc8;
      }
      &.tableHeader {
        font-weight: bold;
      }
    }
    .tableColTop {
      border-left: 2px solid #a5adc5;
      height: 30px;
    }
    .tableLink {
      margin: 0 auto;
      @media (max-width: $md5+px) {
        width: 100%;
      }
      span {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}

.grid12,
.grid17,
.grid21,
.grid25 {
  @media (max-width: $md2+px) {
    & > div {
      width: 215px !important;
    }
  }
}
