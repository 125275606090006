@import '../../../styles/var';

.invincible {
    margin-top: 100px;
    @media(max-width: $md6+px) {
        margin-top: 70px;
    }
    .header {
        position: relative;
        z-index: 3;
        text-align: center;
    }
    .content {
        position: relative;
        z-index: 2;
        margin-top: 90px;
        @media(max-width: 700px) {
            margin-top: 35px;
        }
        .planet {
            margin: 0 auto;
            display: flex;
            @media(max-width: $md2+px) {
                width: 500px;
            }
            @media(max-width: 700px) {
                width: 242px;
            }
        }
        .plate {
            position: absolute;
            z-index: 3;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            color: #FFFFFF;
            width: 300px;
            text-align: center;
            padding: 15px 0;
            background: linear-gradient(180deg, #563687 0%, #C1A1E1 100%);
            opacity: 0.85;
            border-radius: 30px;
            transition: all .5s;
            @media(max-width: $md2+px) {
                width: 250px;
                padding: 13px 0;
                font-size: 16px;
                line-height: normal;
            }
            @media(max-width: 700px) {
                font-size: 10px;
                line-height: 15px;
                width: 150px;
                border-radius: 15px;
                padding: 7px 0;
            }
            &:hover {
                @media(min-width: 700px) {
                    padding: 20px 0;
                    width: 320px;
                    @media(max-width: $md2+px) {
                        padding: 15px 0;
                        width: 270px;
                    }
                }
            }
            &_01 {
                top: 0;
                left: 17%;
                @media(max-width: $md4+px) {
                    left: 10%;
                }
                @media(max-width: 700px) {
                    top: -20px;
                }
                @media(max-width: $md6+px) {
                    left: 1%;
                }
            }
            &_02 {
                top: 0;
                left: 60%;
                @media(max-width: $md4+px) {
                    left: unset;
                    right: 10%;
                }
                @media(max-width: 700px) {
                    top: 10px;
                }
                @media(max-width: $md6+px) {
                    right: 1%;
                }
            }
            &_03 {
                top: 180px;
                left: 25%;
                @media(max-width: $md4+px) {
                    left: 20%;
                    top: 150px;
                }
                @media(max-width: 700px) {
                    top: 60px;
                }
                @media(max-width: $md6+px) {
                    left: 15%;
                }
            }
            &_04 {
                top: 140px;
                left: 75%;
                @media(max-width: $md4+px) {
                    left: unset;
                    right: 1%;
                    top: 130px;
                }
                @media(max-width: 700px) {
                    top: 110px;
                }
            }
            &_05 {
                top: 320px;
                left: 5%;
                @media(max-width: $md4+px) {
                    top: 290px;
                }
                @media(max-width: 700px) {
                    top: 140px;
                }
            }
            &_06 {
                top: 320px;
                left: 55%;
                @media(max-width: $md4+px) {
                    top: 270px;
                    left: unset;
                    right: 5%;
                }
                @media(max-width: 700px) {
                    top: 190px;
                }
            }
        }
    }
    .btn {
        position: relative;
        z-index: 3;
        margin-top: 75px;
        display: flex;
        justify-content: center;
    }
}