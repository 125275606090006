@import '../../../styles/var';

.quickFacts {
    margin-top: 100px;
    @media(max-width: $md6+px) {
        margin-top: 70px;
    }
    .header {
        text-align: center;
    }
    .content {
        margin-top: 100px;
        position: relative;
        @media(max-width: $md6+px) {
            margin-top: 22px;
        }
        .items {
            position: absolute;
            z-index: 2;
            width: 100%;
            top: 0;
            left: 0;
            @media(max-width: $md3+px) {
                display: none;
            }
            .item {
                &_01 {
                    position: absolute;
                    top: -50px;
                    left: 5%;
                }
                &_02 {
                    position: absolute;
                    top: 50px;
                    left: 15%;
                }
                &_03 {
                    position: absolute;
                    top: -60px;
                    left: 25%;
                }
                &_04 {
                    position: absolute;
                    top: 280px;
                    right: 20%;
                }
                &_05 {
                    position: absolute;
                    top: 210px;
                    right: 5%;
                }
                &_06 {
                    position: absolute;
                    width: 50px;
                    transform: rotate(180deg);
                    top: 530px;
                    left: 15%;
                }
                &_07 {
                    position: absolute;
                    top: 420px;
                    left: 22%;
                }
                &_08 {
                    position: absolute;
                    top: 600px;
                    right: 23%;
                }
                &_09 {
                    position: absolute;
                    top: 700px;
                    right: 15%;
                    width: 50px;
                    transform: rotate(120deg);
                }
            }
        }
        .plate {
            position: relative;
            z-index: 3;
            margin-top: 40px;
            width: 1250px;
            background: linear-gradient(90deg, #E8EAF1 2.4%, rgba(235, 236, 240, 0) 98.82%);
            border: 2px solid #FFFFFF;
            filter: drop-shadow(8px 7px 21px rgba(0, 0, 0, 0.15)) drop-shadow(-6px -7px 11px rgba(255, 255, 255, 0.84));
            display: flex;
            padding: 32px 0;
            @media(max-width: $md1+px) {
                width: 1000px;
            }
            @media(max-width: $md2+px) {
                width: 900px;
            }
            @media(max-width: $md3+px) {
                padding: 25px 0;
            }
            @media(max-width: $md4+px) {
                width: 700px;
                margin-top: 20px;
            }
            @media(max-width: $md5+px) {
                width: 500px;
                padding: 12px 0;
            }
            @media(max-width: 510px) {
                width: calc(100% - 10px);
            }
            &:hover {
                .num {
                    -webkit-text-fill-color: #7F74D2;
                }
            }
            .num {
                font-weight: 800;
                font-size: 129px;
                line-height: 100px;
                display: flex;
                align-items: center;
                -webkit-text-stroke: 1px #7F74D2;
                -webkit-text-fill-color: transparent;
                @media(max-width: $md2+px) {
                    font-size: 100px;
                    line-height: 80px;
                }
                @media(max-width: $md5+px) {
                    font-size: 70px;
                    line-height: 50px;
                }
            }
            .text {
                .first {
                    font-weight: 800;
                    font-size: 20px;
                    line-height: 20px;
                    color: #36336C;
                    @media(max-width: $md2+px) {
                        font-size: 18px;
                        line-height: 18px;
                    }
                    @media(max-width: $md5+px) {
                        font-size: 9px;
                        line-height: 9px;
                    }
                }
                .second {
                    margin-top: 10px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                    width: 686px;
                    @media(max-width: $md2+px) {
                        font-size: 12px;
                        line-height: normal;
                        width: 520px;
                    }
                    @media(max-width: $md5+px) {
                        font-size: 8px;
                        line-height: 12px;
                        width: 308px;
                        margin-top: 5px;
                    }
                    @media(max-width: 400px) {
                        width: calc(100% - 8px);
                        // margin-left: auto;
                    }
                }
            }
            &Left {
                border-top-right-radius: 100px;
                border-end-end-radius: 100px;
                border-left: none;
                justify-content: flex-end;
                text-align: right;
                .num {
                    margin: 0 63px 0 40px;
                    @media(max-width: $md5+px) {
                        margin: 0 29px 0 11px;
                    }
                    @media(max-width: 420px) {
                        margin: 0 20px 0 10px;
                    }
                }
                .text {
                    .second {
                        @media(max-width: 400px) {
                            margin-left: auto;
                        }
                    }
                }
            }
            &Right {
                border-top-left-radius: 100px;
                border-end-start-radius: 100px;
                border-right: none;
                margin-left: auto;
                .num {
                    margin: 0 40px 0 63px;
                    @media(max-width: $md5+px) {
                        margin: 0 11px 0 29px;
                    }
                    @media(max-width: 420px) {
                        margin: 0 10px 0 20px;
                    }
                }
            }
            &_01 {

            }
            &_02 {
                
            }
            &_03 {
                
            }
            &_04 {
                
            }
        }
    }
}