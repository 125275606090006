@import "../../styles/_var.scss";
.spoiler {
    background: linear-gradient(90deg, #cfd6e9 2.4%, #ebf1ff 98.82%);
    border: 2px solid #ffffff;
    box-shadow: 8px 7px 21px rgba(0, 0, 0, 0.15), -6px -7px 11px rgba(255, 255, 255, 0.84);
    border-radius: 50px;
    width: 100%;
    padding: 23px 56px 23px 75px;
    margin-bottom: 40px;
    cursor: pointer;
    position: relative;
    z-index: 2;
    @media (max-width: $md4+px) {
        padding: 23px 30px 23px 30px;
        margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
        padding: 23px 20px 23px 20px;
        margin-bottom: 20px;
    }

    transition: all 0.3s linear;
    &.active {
        .spoilerContent {
            opacity: 1;
            margin-top: 30px;
            font-size: 20px;
            @media (max-width: $md5+px) {
                font-size: 18px;
            }
            @media (max-width: $md5+px) {
                font-size: 16px;
            }
            @media (max-width: $md5+px) {
                font-size: 14px;
                margin-top: 20px;
            }
            @media (max-width: $md6+px) {
                font-size: 12px;
                margin-top: 10px;
            }
        }
        .spoilerTitle {
            &:before {
                transform: rotate(90deg);
            }
        }
    }
}
.spoilerTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 25px;
    line-height: 1;
    color: #36336c;
    position: relative;
    @media (max-width: $md5+px) {
        font-size: 22px;
        padding-right: 50px;
    }
    @media (max-width: $md5+px) {
        font-size: 19px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 12px;
    }
    &:after {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: #36336c;
        @media (max-width: $md6+px) {
            width: 12px;
            height: 1px;
        }
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 9px;
        top: 0px;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 2px;
        height: 20px;
        background-color: #36336c;
        transition: all 0.3s linear;
        @media (max-width: $md6+px) {
            width: 1px;
            height: 12px;
            right: 5px;
        }
    }
}
.spoilerContent {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    line-height: 1.3;
    color: #000000;
    position: relative;
    transition: all 0.3s linear;
    opacity: 0;
    font-size: 0;
    margin-top: 0px;
}
