@import "../../../styles/_var.scss";
.forex {
    &.hover {
        .forexBody {
            top: 0;
        }
    }
}
.forexBody {
    margin-top: 100px;
    margin-bottom: 80px;
    @media (max-width: $md3+px) {
        margin-top: 80px;
        top: 0;
    }
    @media (max-width: $md4+px) {
        margin-top: 60px;
    }
    position: relative;
    top: 200px;
    transition: all 1s linear;
}
.forexRow {
    display: flex;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.forexRowLeft {
    flex: 0 1 696px;
    margin-right: 300px;
    @media (max-width: $md1+px) {
        margin-right: 200px;
    }
    @media (max-width: $md3+px) {
        margin-right: 30px;
    }
    @media (max-width: $md4+px) {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
.forexTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1.1;
    color: #36336c;
    margin-bottom: 30px;

    @media (max-width: $md2+px) {
        margin-bottom: 15px;
        font-size: 35px;
    }
    @media (max-width: $md4+px) {
        font-size: 30px;
    }
    @media (max-width: $md5+px) {
        font-size: 25px;
    }
}
.forexSubtitle {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 15px;
    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.forexRowRight {
    width: 350px;
    position: relative;
    top: 50px;
    img {
        width: 100%;
    }
    @media (max-width: $md4+px) {
        top: 0;
        flex: 0 1 100%;
        text-align: center;
        img {
            width: 325px;
        }
    }
    @media (max-width: $md6+px) {
        img {
            width: 300px;
        }
    }
}
