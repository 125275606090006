@import "../../../styles/_var.scss";
.benefits {
    &.hover {
        .benefitsBody {
            top: 0;
        }
    }
}
.benefitsBody {
    position: relative;
    top: 200px;
    transition: all 1s linear;
    @media(max-width: $md3+px) {
        top: 0!important;
    }
}
.benefitsRow {
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
    @media (max-width: $md4+px) {
    }
}
.benefitsRowLeft {
    flex: 0 1 691px;
    margin-bottom: 80px;

    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 450px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 60px;
        order: 0;
    }
}
.benefitsRowLeftTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1;
    color: #36336c;
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        font-size: 34px;
    }
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}
.benefitsRowLeftSubtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    color: #000000;
    margin-bottom: 10px;
    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.benefitsRowLeftList {
    p {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 20px;
        line-height: 1.3;
        color: #000000;
        margin-bottom: 5px;
        position: relative;
        padding-left: 35px;

        @media (max-width: $md4+px) {
            font-size: 18px;
        }
        @media (max-width: $md5+px) {
            font-size: 16px;
        }
        @media (max-width: $md6+px) {
            font-size: 14px;
        }
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 2px;
            position: absolute;
            width: 30px;
            height: 30px;
            background: url("../../../assets/img/education/3/1.png") 0 0 / contain no-repeat;
            @media (max-width: $md4+px) {
                top: 0px;
            }
            @media (max-width: $md5+px) {
                top: -2px;
            }
        }
    }
}
.benefitsRowRight {
    flex: 0 1 691px;
    @media (max-width: $md1+px) {
        flex: 0 1 600px;
    }
    @media (max-width: $md2+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 500px;
    }
    &:nth-child(2) {
        @media (max-width: $md4+px) {
            margin-top: 30px;
        }
    }
    @media (max-width: $md4+px) {
        &:nth-child(1) {
            order: 2;
        }
        &:nth-child(2) {
            order: 3;
        }
    }
}
.benefitsRowRightTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1;
    color: #36336c;
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        font-size: 34px;
    }
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}
.benefitsRowRightSubtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    color: #000000;
    margin-bottom: 15px;
    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.benefitsStart {
    margin-top: 70px;
}
.benefitsRowImg {
    flex: 0 1 600px;
    height: 450px;
    position: relative;
    top: -250px;
    @media (max-width: $md2+px) {
        flex: 0 1 500px;
        top: -100px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 400px;
        top: -50px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        height: auto;
        order: 4;
    }
    img {
        width: 100%;
    }
}
