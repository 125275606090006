@import "../../../styles/_var.scss";
.about {
}
.aboutBody {
    margin-top: 100px;
    margin-bottom: 85px;
    @media (max-width: $md4+px) {
        margin-bottom: 70px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 50px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 25px;
    }
}
.aboutRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $md5+px) {
        flex-wrap: wrap;
    }
}
.aboutRowLeft {
    flex: 0 1 696px;
    position: relative;
    top: -60px;
    @media (max-width: $md2+px) {
        top: -30px;
    }
    @media (max-width: $md3+px) {
        top: 0;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 550px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 30px;
    }
}
.aboutTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 70px;
    line-height: 1.1;
    color: #36336c;
    margin-bottom: 30px;
    @media (max-width: $md1+px) {
        font-size: 60px;
    }
    @media (max-width: $md2+px) {
        font-size: 50px;
    }
    @media (max-width: $md4+px) {
        font-size: 45px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
        margin-bottom: 15px;
    }
}
.aboutSubtitle {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 70px;
    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
        margin-bottom: 50px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.aboutStart {
}
.aboutRowRight {
    @media (max-width: $md2+px) {
        img {
            width: 100%;
        }
        flex: 0 1 500px;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 400px;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 100%;
        text-align: center;
        img {
            width: 400px;
            margin: 0 auto;
        }
    }
    @media (max-width: $md6+px) {
        img {
            width: 288px;
        }
    }
}
