@import '../styles/var';

.wrap {
   
    height: 100vh;
    min-height: 500px;


    font-size: 40px;
    line-height: 160%;
    font-weight: 400;

    @media(max-width: $md6+px) {
        font-size: 25px;
        min-height: 350px;
    }

    b {
        font-weight: 600;
        font-size: 50px;
        text-transform: uppercase;

        @media(max-width: $md6+px) {
            font-size: 35px;
        }
    }

    .content {
        display: flex;
        align-self: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        height: 100%;
        gap: 30px;

        .image {
            width: 200px;
            margin: 0 auto;

            @media(max-width: $md6+px) {
                width: 100px;
            }
        }
    }
}