@import "../../../styles/_var.scss";
.why {
    &.hover {
        .whyBody {
            top: 0;
        }
    }
}
.whyBody {
    margin-bottom: 87px;
    @media (max-width: $md3+px) {
        margin-bottom: 70px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 60px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 40px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
    position: relative;
    top: 200px;
    transition: all 1s linear;
    @media(max-width: $md3+px) {
        top: 0!important;
    }
}
.whyRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.whyRowLeft {
    flex: 0 1 696px;
    @media (max-width: $md1+px) {
        flex: 0 1 48%;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        order: 2;
    }
}
.whyRowLeftTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1.1;
    color: #36336c;
    margin-bottom: 30px;

    @media (max-width: $md2+px) {
        margin-bottom: 15px;
        font-size: 35px;
    }
    @media (max-width: $md4+px) {
        font-size: 30px;
    }
    @media (max-width: $md5+px) {
        font-size: 25px;
    }
}
.whyRowLeftSubtitle {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 15px;
    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}

.whyRowRight {
    flex: 0 1 696px;
    @media (max-width: $md1+px) {
        flex: 0 1 48%;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 100%;
        margin-bottom: 15px;
    }
}
.whyRowRightTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1.1;
    color: #36336c;
    margin-bottom: 30px;

    @media (max-width: $md2+px) {
        margin-bottom: 15px;
        font-size: 35px;
    }
    @media (max-width: $md4+px) {
        font-size: 30px;
    }
    @media (max-width: $md5+px) {
        font-size: 25px;
    }
}
.whyRowRightSubtitle {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 1.4;
    color: #000000;

    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    margin-bottom: 40px;
    @media (max-width: $md4+px) {
        margin-bottom: 15px;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
