@import '../../../styles/var';

@import '../../../styles/var';

.allMarketsTitle {
  font-size: 50px;
  font-weight: bold;
  color: #36336C;
  margin-bottom: 50px;
  @media (max-width: $md4+px) {
    font-size: 25px;
    margin: 70px auto 30px;
  }
}

.allMarketText {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  @media (max-width: $md4+px) {
    font-size: 14px;
  }
}

.allMarketsRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  @media (max-width: $md4+px) {
    margin-top: 0px;
  }
  .allMarketsCol6 {
    flex: 0 0 auto;
    width: 50%;
    @media (max-width: $md4+px) {
      width: 100%;
    }
    .allMarketsList {
      margin-top: 25px;
      .allMarketsItem {
        padding-left: 57px;
        position: relative;
        @media (max-width: $md4+px) {
          padding-left: 40px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 27px;
          height: 27px;
          box-shadow: inset -0.901293px 0.901293px 1.80259px #8181C9;
          filter: drop-shadow(-2.70388px 3.60517px 9.01293px rgba(0, 0, 0, 0.5));
          background: #2A2D54;
          border-radius: 50%;
          @media (max-width: $md4+px) {
            width: 20px;
            height: 20px;
          }
        }
        .allMarketsItemTitle {
          font-size: 30px;
          font-weight: 600;
          color: #36336C;
          margin-bottom: 10px;
          @media (max-width: $md4+px) {
            font-size: 14px;
          }
        }
        .allMarketsItemText {
          font-size: 20px;
          font-weight: 300;
          max-width: 465px;
          width: 100%;
          margin-bottom: 25px;
          @media (max-width: $md4+px) {
            font-size: 14px;
          }
        }
      }
    }
    .allMarketsLinkWrap {
      margin-top: 70px;
      display: flex;
      justify-content: center;
    }
    &.allMarketsImgWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $md4+px) {
        margin-top: 40px;
        display: none;
      }
      .imgFluid {
        width: 100%;
        height: auto;
      }
    }
    &.allMarketsImgWrapMobile {
      display: none;
      @media (max-width: $md4+px) {
        display: block;
      }
      .imgFluid {
        width: 100%;
        height: auto;
      }
    }

  }
}
