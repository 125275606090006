@import "../../styles/_var.scss";
.btn {
    position: relative;
    z-index: 4;
    width: 235px;
    height: 50px;
    border-radius: 31px;
    background-color: #dde3ef;

    position: relative;
    transition: all 0.3s linear;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 31px;
        box-shadow: -8px -8px 10px #ffffff, 6px 5px 10px 3px rgba(54, 54, 54, 0.24);
        transition: all 0.3s linear;
    }
    &:before {
        content: "";
        height: 42px;
        width: 95%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 31px;
        transition: all 0.3s linear;
        background: linear-gradient(91.62deg, #3d3e70 4.05%, #ebecf0 100%, #ccd0eb 100%);
        opacity: 0;
    }
    a, span {
        position: relative;
        z-index: 10;
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 14px;
        color: #000;
        height: 42px;
        width: 95%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 31px;
        transition: all 0.3s linear;
        background: linear-gradient(91.62deg, #b2b3d1 4.05%, #d2d5e8 58.57%, #ebecf0 100%, #ccd0eb 100%);
    }
    &:hover {
        a, span {
            color: #fff;

            background: transparent;
        }
        &:before {
            opacity: 1;
        }
    }
    &:active {
        &:after {
            box-shadow: 6px 5px 10px 3px #ffffff, -8px -8px 10px rgba(54, 54, 54, 0.24);
        }
    }
    &.head {
        a, span {
            font-weight: 500;
            font-size: 20px;
        }
        @media (max-width: $md2+px) {
            a, span {
                font-size: 14px;
            }
        }
        @media (max-width: $md2+px) {
            width: 67px;
            height: 36px;
            a, span {
                height: 32px;
            }
            &:before {
                height: 32px;
            }
        }
    }
}

.btn1 {
    position: relative;
    z-index: 10;
    width: 235px;
    height: 50px;
    border-radius: 31px;
    background-color: #dde3ef;
    position: relative;
    transition: all 0.3s linear;
    cursor: pointer;
    text-transform: uppercase;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
    transition: all 0.3s linear;
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 31px;

        box-shadow: -8px -8px 10px #ffffff, 6px 5px 10px 3px rgba(54, 54, 54, 0.24);
        transition: all 0.3s linear;
    }
    &:before {
        content: "";
        height: 42px;
        width: 95%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.3s linear;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 31px;
        transition: all 0.3s linear;
        background: linear-gradient(91.62deg, #b2b3d1 4.05%, #d2d5e8 58.57%, #ebecf0 100%, #ccd0eb 100%);
        opacity: 0;
    }
    a, span {
        z-index: 10;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        height: 42px;
        width: 95%;
        overflow: hidden;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 31px;
        transition: all 0.3s linear;

        background: linear-gradient(91.62deg, #3d3e70 4.05%, #ebecf0 100%, #ccd0eb 100%);
    }
    &:hover {
        a, span {
            color: #000;

            background: rgba(1, 1, 1, 0);
        }
        &:before {
            opacity: 1;
        }
    }
    &:active {
        &:after {
            box-shadow: 6px 5px 10px 3px #ffffff, -8px -8px 10px rgba(54, 54, 54, 0.24);
        }
    }
    &.head1 {
        a, span {

            font-size: 20px;
            @media (max-width: $md2+px) {
                font-size: 14px;
            }
        }
        @media (max-width: $md2+px) {
            width: 67px;
            height: 36px;
            a, span {
                height: 32px;
            }
            &:before {
                height: 32px;
            }
        }
    }
}
