@import "../../../styles/_var.scss";
.support {
}
.supportBody {
    margin: 128px 0 100px 0;

    @media (max-width: $md6+px) {
        margin: 100px 0 90px 0;
    }
    @media (max-width: $md6+px) {
        margin: 80px 0 80px 0;
    }
    @media (max-width: $md6+px) {
        margin: 60px 0 60px 0;
    }
}
.supportWrapper {
    position: relative;
    max-width: 1220px;
    margin: 0 auto;
    @media (max-width: $md1+px) {
        max-width: 1000px;
    }
    @media (max-width: 1380px) {
        max-width: 800px;
    }
    @media (max-width: $md6+px) {
    }
}
.supportTitle {
    padding-left: 60px;
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 70px;
    line-height: 1.1;
    color: #36336c;
    margin-bottom: 80px;
    @media (max-width: $md1+px) {
        font-size: 60px;
    }
    @media (max-width: $md2+px) {
        font-size: 50px;
    }
    @media (max-width: $md4+px) {
        font-size: 45px;
        margin-bottom: 50px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
        margin-bottom: 30px;

        flex-direction: column;
        margin-left: 0;
        padding-left: 0;
    }
    @media (max-width: $md6+px) {
        font-size: 30px;
    }
    img {
        margin-right: 38px;
        @media (max-width: $md2+px) {
            margin-right: 0;
        }
        @media (max-width: $md3+px) {
            width: 112px;
        }
    }
}
.supportStart {
    margin-top: 50px;
    @media (max-width: $md5+px) {
        display: flex;
        justify-content: center;
    }
}

.supportStart {
}
.supportIcon1,
.supportIcon2,
.supportIcon3,
.supportIcon4,
.supportIcon5,
.supportIcon6,
.supportIcon7 {
    position: absolute;
    @media (max-width: 1385px) {
        display: none;
    }
}
.supportIcon1 {
    top: 340px;
    left: -190px;
}
.supportIcon2 {
    top: 370px;
    left: -130px;
}
.supportIcon3 {
    top: 580px;
    left: -190px;
}
.supportIcon4 {
    right: -170px;
    top: 220px;
}
.supportIcon5 {
    right: -115px;
    top: 325px;
}
.supportIcon6 {
    right: -190px;
    top: 470px;
}
.supportIcon7 {
    right: -70px;
    top: 650px;
}
