@import '../../../styles/var';

.forexTrading {
    margin-top: 100px;
    position: relative;
    z-index: 2;
    @media(max-width: $md6+px) {
        margin-top: 70px;
    }
    .content {
        width: 690px;
        @media(max-width: $md2+px) {
            width: 600px;
        }
        @media(max-width: $md3+px) {
            width: 100%;
        }
        .text {
            position: relative;
            z-index: 5;
            margin: 20px 0 70px 0;
            font-weight: 300;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
            @media(max-width: $md2+px) {
                font-size: 18px;
                line-height: normal;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
                line-height: 17px;
            }
            .par {
                margin-top: 15px;
            }
        }
    }
    .images {
        position: absolute;
        top: 40px;
        right: 0;
        @media(max-width: $md1+px) {
            right: -70px;
        }
        @media(max-width: $md3+px) {
            position: relative;
            height: 350px;
            scale: 0.7;
            width: 650px;
            display: flex;
            margin: 0 auto;
            right: unset;
        }
        @media(max-width: $md5+px) {
            scale: 0.5;
            height: 220px;
            width: 330px;
        }
        @media(max-width: $md6+px) {
            scale: 1;
            height: 190px;
        }
        img {
            position: absolute;
            top: 0;
        }
        .eth {
            z-index: 3;
            right: 220px;
            top: 20px;
            @media(max-width: $md5+px) {
                right: 60px;
            }
            @media(max-width: $md6+px) {
                width: 100px;
                right: 110px;
            }
        }
        .euroLeft {
            z-index: 2;
            right: 420px;
            transform: rotate(5deg);
            @media(max-width: $md5+px) {
                right: 250px;
            }
            @media(max-width: $md6+px) {
                width: 50px;
                right: 210px;
            }
        }
        .euroRight {
            z-index: 2;
            right: 160px;
            top: 250px;
            transform: rotate(20deg);
            scale: 0.8;
            @media(max-width: $md5+px) {
                right: 0;
            }
            @media(max-width: $md6+px) {
                width: 30px;
                scale: 1;
                top: 140px;
                right: 90px;
            }
        }
        .shield {
            z-index: 2;
            right: 550px;
            scale: 0.8;
            top: 50px;
            @media(max-width: $md2+px) {
                right: 490px;
            }
            @media(max-width: $md5+px) {
                right: 350px;
                top: 70px;
            }
            @media(max-width: $md6+px) {
                scale: 1;
                right: 0;
                width: 50px;
                right: unset;
                left: 00px;
            }
        }
        .sphere {
            z-index: 4;
            right: 370px;
            top: 290px;
            @media(max-width: $md5+px) {
                right: 240px;
            }
            @media(max-width: $md6+px) {
                right: unset;
                left: 90px;
                top: 150px;
                width: 30px;
            }
        }
        .bagel {
            z-index: 2;
            right: 150px;
            @media(max-width: $md5+px) {
                right: 0;
            }
            @media(max-width: $md6+px) {
                width: 80px;
                right: 30px;
            }
        }
    }
}