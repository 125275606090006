@import '../../../styles/var';

.byInvesting {
    position: relative;
    margin-top: 70px;
    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 0;
    }
    .content {
        @media(max-width: $md3+px) {
            margin-top: 40px;
        }
        @media(max-width: $md6+px) {
            text-align: center;
            margin-top: 20px;
        }
        .header {
            width: 828px;
            @media(max-width: $md1+px) {
                width: 700px;
            }
            @media(max-width: $md5+px) {
                width: 100%;
            }
        }
        .second {
            font-weight: 800;
            font-size: 120px;
            line-height: 180px;
            color: #514486;
            @media(max-width: $md5+px) {
                font-size: 100px;
                line-height: 140px;
            }
            @media(max-width: $md6+px) {
                font-size: 50px;
                line-height: 75px;
            }
        }
        .btn {
            margin-top: 70px;
            @media(max-width: $md6+px) {
                margin-top: 50px;
                display: flex;
                justify-content: center;
            }
        }
    }
    .stairs {
        position: absolute;
        right: 74px;
        bottom: -40px;
        @media(max-width: $md1+px) {
            right: 0;
        }
        @media(max-width: $md3+px) {
            position: relative;
            margin: 0 auto;
            scale: 0.8;
        }
        @media(max-width: $md5+px) {
            scale: 0.6;
        }
        @media(max-width: $md6+px) {
            width: 60%;
            scale: 1;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }
}