@import '../../../styles/var';

.makeMoney {
    margin-top: 100px;
    @media (max-width: $md6+px) {
        margin-top: 70px;
    }
    .header {
        text-align: center;
    }
    .text {
        margin: 20px 0 70px 0;
        text-align: center;
        font-weight: 300;
        font-size: 25px;
        line-height: 38px;
        color: #060606;
        @media(max-width: $md6+px) {
            font-size: 18px;
            line-height: normal;
            margin: 15px 0 30px 0;
        }
    }
    .cards {
        display: flex;
        justify-content: space-evenly;
        @media(max-width: $md4+px) {
            display: block;
        }
        .card {
            text-align: center;
            &:hover .cardIcon img {
                // scale: 1.1;
                transform: rotate(10deg);
            }
            @media(max-width: $md4+px) {
                margin-bottom: 30px;
            }
            &Icon {
                img {
                    transition: all .3s;
                    @media(max-width: $md1+px) {
                        width: 180px;
                    }
                    @media(max-width: $md2+px) {
                        width: 150px;
                    }
                    @media(max-width: $md6+px) {
                        
                    }
                }
            }
            &Text {
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
                @media(max-width: $md1+px) {
                    font-size: 18px;
                    line-height: normal;
                }
                @media(max-width: $md2+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
        }
    }
    .btn {
        margin-top: 70px;
        justify-content: center;
        display: flex;
    }
}