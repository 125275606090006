@import "../../../styles/_var.scss";
.how {
    &.scroll {
        .howBody {
            top: 0;
        }
        @media (min-width: $md5+px) {
            .howImg1 {
                left: 10px;
                top: -12px;
            }
            .howImg2 {
                left: 25px;
                top: 80px;
            }
            .howImg3 {
                left: -75px;
                top: 215px;
            }
            .howImg4 {
                left: 80px;
                top: 320px;
            }
            .howImg5 {
                right: 0;
                top: -55px;
            }
            .howImg6 {
                right: -75px;
                top: 55px;
            }
            .howImg7 {
                right: 120px;
                top: 147px;
            }
            .howImg8 {
                right: 110px;
                bottom: 65px;
            }
        }
    }
}
.howBody {
    margin-top: 120px;
    margin-bottom: 100px;
    position: relative;
    top: 100px;
    transition: top 1s linear;
    @media (max-width: $md3+px) {
        margin-top: 100px;
        margin-bottom: 80px;
        top: 0!important;
    }
    @media (max-width: $md4+px) {
        margin-top: 50px;
        margin-bottom: 70px;
    }
    @media (max-width: $md5+px) {
        margin-top: 20px;
        margin-bottom: 70px;
    }
}
.howWrapper {
}
.howTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1;
    text-align: center;
    color: #36336c;
    margin-bottom: 30px;
    @media (max-width: $md3+px) {
        font-size: 34px;
    }
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md5+px) {
        font-size: 28px;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
        margin-bottom: 15px;
    }
}
.howSubtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 30px;
    text-align: center;
    line-height: 1.3;
    color: #000000;
    margin-bottom: 50px;
    @media (max-width: $md4+px) {
        font-size: 25px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 40px;
        font-size: 20px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
        font-size: 17px;
    }
}
.howTextWrapper {
    position: relative;

    width: 679px;
    margin: 0 auto;
    @media (max-width: $md5+px) {
        width: 100%;
    }
    &.hover {
        .howImg1 {
            left: -30px;
            top: -60px;
        }
        .howImg2 {
            left: -155px;
            top: 0px;
            transform: rotate(80deg);
            @media (max-width: $md4+px) {
                left: -100px;
                top: 40px;
            }
        }
        .howImg3 {
            left: -185px;
            top: 215px;
            transform: rotate(70deg);
            @media (max-width: $md4+px) {
                left: -120px;
            }
        }
        .howImg4 {
            left: -120px;
            top: 420px;
            @media (max-width: $md4+px) {
                left: -80px;
            }
        }
        .howImg5 {
            right: -140px;
            top: -125px;
            @media (max-width: 1200px) {
                right: -70px;
                top: -85px;
            }
            @media (max-width: $md4+px) {
                right: -40px;
            }
        }
        .howImg6 {
            right: -235px;
            top: 0px;
            transform: rotate(80deg);
            @media (max-width: 1200px) {
                right: -130px;
                top: 80px;
            }
            @media (max-width: $md4+px) {
                right: -80px;
            }
        }
        .howImg7 {
            right: -150px;
            top: 317px;
            transform: rotate(60deg);
            @media (max-width: 1200px) {
                right: -100px;
                top: 300px;
            }
            @media (max-width: $md4+px) {
                right: -70px;
            }
        }
        .howImg8 {
            right: -110px;
            bottom: -90px;
            transform: rotate(40deg);
            @media (max-width: 1200px) {
                right: -70px;
                bottom: -70px;
            }
            @media (max-width: $md4+px) {
                right: -40px;
            }
        }
    }
}
.howText {
    position: relative;
    z-index: 2;
    padding: 10px 45px;
    margin: 0 auto 20px auto;
    text-align: center;
    @media (max-width: $md5+px) {
        padding: 10px 30px;
    }

    span {
        font-family: "Poppins", sans-serif;
        font-weight: 300;
        font-size: 20px;
        line-height: 1.5;
        color: #000000;
        position: relative;
        z-index: 2;
        @media (max-width: $md5+px) {
            font-size: 15px;
        }
        @media (max-width: $md6+px) {
            font-size: 11px;
        }
    }
    &:after {
        content: "";
        display: "inline-block";
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        height: 100%;
        background: linear-gradient(135deg, #d5d7e5 0%, #ebedfd 100%);
        box-shadow: -1px 1px 2px rgba(181, 183, 195, 0.2), 1px -1px 2px rgba(181, 183, 195, 0.2),
            -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 5px 30px rgba(181, 183, 195, 0.25),
            inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(181, 183, 195, 0.5);
        border-radius: 25px;
        opacity: 0.8;
    }
}
.howBtn {
    margin-top: 70px;
    display: flex;
    justify-content: center;
    @media (max-width: $md6+px) {
        margin-top: 50px;
    }
}
.howImg1,
.howImg2,
.howImg3,
.howImg4,
.howImg5,
.howImg6,
.howImg7,
.howImg8 {
    position: absolute;
    transition: all 1s linear;
    @media (max-width: $md5+px) {
        display: none;
    }
    img {
        width: 100%;
    }
}
.howImg1 {
    left: 10px;
    top: -52px;
}
.howImg2 {
    left: 25px;
    top: 30px;
}
.howImg3 {
    left: -75px;
    top: 175px;
}
.howImg4 {
    left: 80px;
    top: 280px;
}
.howImg5 {
    right: 0;
    top: -45px;
}
.howImg6 {
    right: -75px;
    top: 20px;
}
.howImg7 {
    right: 120px;
    top: 107px;
}
.howImg8 {
    right: 110px;
    bottom: 105px;
}
