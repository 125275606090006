@import "../../../styles/var";

.advantagesItemsContainer {
  max-width: 1370px;
  margin: 100px auto 0;
  padding: 0 10px;
  @media (max-width: $md4+px) {
    margin: 100px auto 0;
  }
  @media (max-width: $md5+px) {
    margin: 50px auto 0;
  }
  .advantagesItemsRow {
    display: flex;
    flex-wrap: wrap;
    .col3 {
      flex: 0 0 auto;
      width: 25%;
      @media (max-width: $md4+px) {
        width: 50%;
      }
      &:first-child {
        .advantagesItemWrap {
          img {
            width: 99px;
            height: 99px;
          }
        }
      }
      &:nth-child(2) {
        .advantagesItemWrap {
          img {
            width: 70px;
            height: 69px;
          }
        }
      }
      &:nth-child(3) {
        .advantagesItemWrap {
          img {
            width: 83px;
            height: 94px;
          }
        }
      }
      &:nth-child(4) {
        .advantagesItemWrap {
          img {
            width: 97px;
            height: 80px;
          }
        }
      }
      .advantagesItemWrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .advantagesItemImgWrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 135px;
          height: 135px;
          background: linear-gradient(70.36deg, #b7bad9 25.96%, #c7caeb 60.37%, #ebecf0 98.08%, #d0d0e0 98.08%);
          border: 2px solid #cfd6e9;
          box-shadow: -8px -8px 10px #ffffff, 6px 5px 10px 3px rgba(54, 54, 54, 0.24);
          border-radius: 100%;
        }
        img {
          max-width: 100%;
          height: auto;
        }
        p {
          font-size: 20px;
          line-height: 30px;
          margin-top: 30px;
          text-align: center;
          @media (max-width: $md4+px) {
            font-size: 12px;
            line-height: 18px;
            margin-top: 15px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}
