@import "../../../styles/var";

.col6 {
  flex: 0 0 auto;
  width: 50%;
  &.planetWrap {
    display: flex;
    align-items: center;
    margin-top: 130px;
    @media (max-width: $md4+px) {
      margin-top: 30px;
    }
    @media (max-width: $md6+px) {
      margin-top: 0px;
    }
  }
  @media (max-width: $md4+px) {
    width: 100%;
  }
}

.bannerRow {
  display: flex;
  flex-wrap: wrap;
  min-height: 620px;
  .bannerTitle {
    font-size: 90px;

    font-weight: bold;
    color: #36336c;
    margin-bottom: 40px;
    margin-top: 200px;
    // font-weight: 800;
    @media (max-width: $md4+px) {
      font-size: 40px;
      text-align: center;
      margin-top: 100px;
    }
    @media (max-width: $md5+px) {
      margin-top: 50px;
    }
  }

  .bannerText {
    font-size: 55px;
    font-weight: 800;
    color: #36336c;
    margin-bottom: 70px;
    @media (max-width: $md4+px) {
      font-size: 24px;
      text-align: center;
    }
    @media (max-width: $md6+px) {
      margin-bottom: 50px;
    }
  }
  .bannerLinkWrap {
    @media (max-width: $md4+px) {
      display: flex;
      justify-content: center;
    }
  }
}
