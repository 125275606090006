@import "../../../styles/_var.scss";
.click {
    &.hover {
        .clickBody {
            top: 0;
        }
    }
}
.clickBody {
    margin-bottom: 120px;
    @media (max-width: $md3+px) {
        margin-bottom: 100px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 80px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 60px;
    }
    position: relative;
    top: 200px;
    transition: all 1s linear;
    @media(max-width: $md3+px) {
        top: 0!important;
    }
}
.clickRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 87px;
    @media (max-width: $md3+px) {
        display: block;
    }
}
.clickRowClick {
    width: 207px;
    height: 207px;
    background: #cfd6e9;
    box-shadow: inset -4px -4px 4px #ffffff, inset 5px 5px 2px rgba(0, 0, 0, 0.12);
    border-radius: 500px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 30px;

    line-height: 1.5;
    color: #36336c;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    @media (max-width: $md1+px) {
        width: 150px;
        font-size: 35px;
        height: 150px;
    }
    @media (max-width: $md3+px) {
        margin: 0 auto 15px auto;
    }
    @media (max-width: 530px) {
        font-size: 22px;
        width: 104px;
        height: 104px;
    }
}
.clickRowArrow {
    width: 132px;
    @media (max-width: $md1+px) {
        width: 90px;
    }
    @media (max-width: $md3+px) {
        margin: 0 auto 15px auto;
        transform: rotate(90deg);
    }
    @media (max-width: $md5+px) {
        width: 64px;
    }
    img {
        width: 100%;
    }
}
.clickRowCenterText {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.4;
    color: #000000;
    width: 317px;
    @media (max-width: $md1+px) {
        font-size: 25px;
        width: 290px;
    }
    @media (max-width: $md3+px) {
        margin: 0 auto 15px auto;
        text-align: center;
    }
    @media (max-width: $md4+px) {
        font-size: 22px;
    }
    @media (max-width: $md5+px) {
        font-size: 18px;
    }
    @media (max-width: 530px) {
        width: 192px;
    }
}
.clickRowRightText {
    width: 643px;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    color: #000000;
    @media (max-width: $md1+px) {
        font-size: 18px;
        width: 550px;
    }
    @media (max-width: $md2+px) {
        width: 400px;
    }
    @media (max-width: $md3+px) {
        margin: 0 auto 0px auto;
        text-align: center;
        font-size: 16px;
    }
    @media (max-width: $md5+px) {
        font-size: 14px;
    }
    @media (max-width: 530px) {
        width: 100%;
    }
}

.clickStart {
    display: flex;
    justify-content: center;
}
