@import '../../../styles/var';

.assistTradeCryptoFirst {
  display: block;
  @media (max-width: $md4+px) {
    display: none;
  }
  .assistTradeImg1 {
    max-width: 200px;
    position: absolute;
    top: -35px;
    left: 20%;
    @media (max-width: $md2+px) {
      left: 10%;
    }
  }
  .assistTradeImg2 {
    max-width: 220px;
    position: absolute;
    top: -120px;
    right: 5%;
  }
}

.assistTradeCryptoSecond {
  display: block;
  @media (max-width: $md4+px) {
    display: none;
  }
  .assistTradeImg3 {
    position: absolute;
    bottom: -10px;
    right: 0;
    max-width: 180px;
    @media (max-width: $md2+px) {
      bottom: -140px;
      right: 0;
    }
  }
  .assistTradeImg4 {
    position: absolute;
    bottom: -175px;
    left: 5%;
    max-width: 210px;
  }
}
.assistTradeSection {
  background-color: #fff;
  padding: 65px 0;
  margin-top: 115px;
  position: relative;
  @media (max-width: $md4+px) {
    padding: 20px 0 20px;
  }
  @media (max-width: $md5+px) {
    padding: 20px 0 0px;
    margin-top: 50px;
  }
  .assistTradeContainer {
    .assistTradeTitle {
      font-size: 50px;
      font-weight: 800;
      color: #36336C;
      text-align: center;
      margin-bottom: 50px;
      @media (max-width: $md4+px) {
        font-size: 25px;
        margin-bottom: 40px;
      }
    }
    .assistTradeRow {
      display: flex;
      flex-wrap: wrap;
      max-width: 1280px;
      width: 100%;
      margin: 0 auto;
      .assistTradeCol3 {
        flex: 0 0 auto;
        width: 25%;
        @media (max-width: $md4+px) {
          width: 50%;
        }
        .assistTradeItem {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .imgFluid {
            max-width: 100%;
            height: auto;
          }
          p {
            font-size: 20px;
            margin-top: 25px;
            text-align: center;
            padding: 0 20px;
            line-height: 30px;
            @media (max-width: $md4+px) {
              font-size: 12px;
              margin-top: 15px;
              line-height: 18px;
              margin-bottom: 60px;
            }
          }
        }
      }
    }
  }
}
