@import "../../../styles/var";

.bestTermsWrap {
  margin-top: 100px;
  @media (max-width: $md6+px) {
    margin-top: 40px;
  }
  @media (max-width: $md5+px) {
    margin-top: 40px;
  }
  .bestTermsTitle {
    font-size: 50px;
    font-weight: bold;
    color: #36336c;
    text-align: center;
    margin-bottom: 30px;
    @media (max-width: $md4+px) {
      font-size: 25px;
      margin-bottom: 40px;
    }
  }
  .bestTermsRow {
    display: flex;
    flex-wrap: wrap;
    .col2 {
      flex: 0 0 auto;
      width: 16.66666667%;
      display: flex;
      flex-direction: column;
      @media (max-width: $mw+px) {
        width: 33.333333%;
        margin-bottom: 30px;
      }
      @media (max-width: $md3+px) {
        width: 33.333333%;
        margin-bottom: 30px;
      }
      @media (max-width: $md4+px) {
        width: 50%;
        margin-bottom: 30px;
      }
      @media (max-width: $md6+px) {
        width: 50%;
        margin-bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:nth-child(2) {
        .bestTermsItem {
          .bestTermsItemTitle {
            @media (max-width: $md6+px) {
              max-width: 70px;
            }
          }
        }
      }
      &:nth-child(4) {
        .bestTermsItem {
          .bestTermsItemTitle {
            @media (max-width: $md6+px) {
              max-width: 70px;
            }
          }
        }
      }
      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5) {
        .bestTermsItem {
          @media (max-width: $md4+px) {
            margin-right: 30px !important;
          }
          @media (max-width: $md6+px) {
            margin-right: 0 !important;
          }
        }
      }
      .bestTermsItem {
        margin-right: 35px;
        @media (max-width: $md6+px) {
          margin-right: 0 !important;
        }
        @media (max-width: $md4+px) {
          margin-right: 0;
        }
      }
      .bestTermsItem {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 70px 15px 20px 15px;
        min-height: 235px;
        @media (max-width: $md6+px) {
          padding-top: 20px;
          background: transparent;
          box-shadow: none;
          height: auto;
          max-width: 200px;
        }
        @media (max-width: $md5+px) {
          padding-top: 0px;
        }
        .imgWrap {
          display: flex;
          align-items: flex-start;
          min-height: 100px;
          .imgFluid {
            margin-bottom: 25px;
          }
        }
        .bestTermsItemTitle {
          font-size: 15px;
          font-weight: bold;
          min-height: 35px;
          color: #36336c;
          text-align: center;
          margin-bottom: 15px;
          @media (max-width: $md6+px) {
            font-size: 12px;
            line-height: 18px;
            height: auto;
            margin-bottom: 15px;
          }
          @media (max-width: $md5+px) {
            max-width: 200px;
          }
        }
        .bestTermsItemText {
          font-size: 14px;
          text-align: center;
          @media (max-width: $md6+px) {
            font-size: 12px;
          }
          @media (max-width: $md5+px) {
            max-width: 200px;
          }
        }
      }
    }
  }
  .bannerTermsLinkWrap {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    @media (max-width: $md5+px) {
      margin-top: 20px;
    }
    @media (max-width: $md4+px) {
      margin-top: 20px;
    }
  }
}
