@import "./_var.scss";
body,
html {
    background-color: #cfd6e9;
    color: black;
    overflow-x: hidden;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.header-default {
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    color: #36336c;
    @media (max-width: $md6+px) {
        font-size: 25px;
        line-height: 38px;
    }
}

.text-default {
}

.btn-default {
    display: inline-flex;
    padding: 15px 46px;
    color: black;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 2px;
    text-transform: uppercase;
    border: 2px solid #dde3ef;
    border-radius: 31px;
    background: linear-gradient(91.62deg, #b2b3d1 4.05%, #d2d5e8 58.57%, #ebecf0 99.23%, #ccd0eb 99.23%);
    box-shadow: -8px -8px 10px #ffffff, 6px 5px 10px 3px rgba(54, 54, 54, 0.24);
    cursor: pointer;
}

body.lock {
    overflow: hidden;
}

// 3d globe in banner from main page
#globe-3d,
#globe-2d-overlay,
#globe-popup-overlay,
.globe-popup {
    display: block;
    position: absolute;
}
#globe-2d-overlay,
#globe-popup-overlay {
    pointer-events: none;
}
.globe-popup-overlay {
    width: 100%;
    height: 100%;
    opacity: 0;
}
.globe-popup {
    top: 0;
    left: 0;
    background-color: rgba(75, 192, 200, 0.9);
    opacity: 0;
    color: #111;
    font-family: sans-serif;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 15px;
    border-radius: 3px;
}
.demo {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animation-wrapper {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 20px;
        bottom: -130px;
        width: 100%;
        height: 154px;
        background-image: url("../assets/img/Main/shadow_phone.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        @media (max-width: $md6+px) {
            left: 5px;
        }
    }
}

.bannerTitle_ru {
    max-width: 500px;
    font-family: "Montserrat" !important;
    @media (max-width: $md4+px) {
        width: 100%;
        text-align: center !important;
        margin: 0 auto;
    }
}
.benefitsRowImg_de {
    top: -200px;
    transform: scale(0.9) !important;
    @media (max-width: $md1+px) {
        top: -170px;
    }
    @media (max-width: $md2+px) {
        top: -40px;
    }
    @media (max-width: $md3+px) {
        top: 0;
    }
}
.benefitsRowImg_ru {
    top: -220px;

    @media (max-width: $md1+px) {
        top: -170px;
    }
    @media (max-width: $md2+px) {
        top: -70px;
    }
    @media (max-width: $md3+px) {
        top: 20px;
    }
}
.benefitsRowImg_es {
    top: -220px;

    @media (max-width: $md1+px) {
        top: -170px;
    }
    @media (max-width: $md2+px) {
        top: -70px;
    }
    @media (max-width: $md3+px) {
        top: 20px;
    }
}
.benefitsRowImg_it {
    top: -250px;

    @media (max-width: $md1+px) {
        top: -190px;
    }
    @media (max-width: $md2+px) {
        top: -70px;
    }
    @media (max-width: $md3+px) {
        top: 20px;
    }
}
.benefitsRowImg_pl {
    top: -250px;

    @media (max-width: $md1+px) {
        top: -190px;
    }
    @media (max-width: $md2+px) {
        top: -70px;
    }
    @media (max-width: $md3+px) {
        top: -10px;
    }
}
.ourRowColumnItemText_de {
    width: 170px !important;
}
.ourRowColumnItemText5_de {
    width: 170px !important;
}
.ourRowColumnItemText5_pl {
    width: 170px !important;
}
.clickRowClick_pl {
    @media (max-width: $md1+px) {
        font-size: 23px;
    }
    @media (max-width: $md6+px) {
        font-size: 15px;
    }
}


.preloader {
    position: fixed;
    z-index: 999999;
    background-color: #CFD6E9;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    top: 0;
    left: 0;
}