@import "../../../styles/_var.scss";
.our {
    &.hover {
        .ourBody {
            top: 0;
        }
    }
}
.ourBody {
    transition: all 1s linear;
    position: relative;
    top: 200px;
    margin-bottom: 120px;
    @media (max-width: $md3+px) {
        margin-bottom: 80px;
        top: 0!important;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 60px;
    }
    @media (max-width: $md5+px) {
        margin-bottom: 40px;
    }
    @media (max-width: $md6+px) {
        margin-bottom: 0px;
    }
}
.ourTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    text-align: center;
    font-size: 40px;
    line-height: 1.4;
    color: #36336c;
    margin-bottom: 60px;
    @media (max-width: $md3+px) {
        font-size: 34px;
    }
    @media (max-width: $md4+px) {
        font-size: 32px;
    }
    @media (max-width: $md5+px) {
        font-size: 28px;
        text-align: left;
    }
    @media (max-width: $md6+px) {
        font-size: 24px;
    }
}
.ourRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
    @media (max-width: $md5+px) {
        justify-content: space-around;
    }
}
.ourRowColumn {
    @media (max-width: $md3+px) {
        margin-bottom: 50px;
        flex: 0 1 33%;
    }
    @media (max-width: $md4+px) {
        flex: 0 1 50%;
    }
    @media (max-width: $md5+px) {
        flex: 0 1 40%;
    }
    &:nth-child(1) {
        .ourRowColumnItemText {
            width: 135px;
            @media (max-width: $md5+px) {
                width: 78.89px;
            }
        }
    }
    &:nth-child(2) {
        .ourRowColumnItemText {
            width: 185px;
            @media (max-width: $md5+px) {
                width: 117.71px;
            }
        }
    }
    &:nth-child(3) {
        .ourRowColumnItemText {
            width: 185px;
            @media (max-width: $md5+px) {
                width: 103.71px;
            }
        }
    }
    &:nth-child(4) {
        .ourRowColumnItemText {
            width: 155px;
            @media (max-width: $md5+px) {
                width: 103.71px;
            }
        }
    }
    &:nth-child(5) {
        .ourRowColumnItemText {
            width: 135px;
            @media (max-width: $md5+px) {
                width: 103.71px;
            }
        }
    }
    &:nth-child(6) {
        .ourRowColumnItemText {
            width: 255px;
            @media (max-width: $md5+px) {
                width: 157.15px;
            }
        }
    }
}
.ourRowColumnItem {
}
.ourRowColumnItemIcon {
    height: 110px;
    margin: 0 auto;
    text-align: center;
    @media (max-width: $md5+px) {
        height: 70px;
    }
    img {
        max-width: 100%;
        @media (max-width: $md5+px) {
            height: 60px;
        }
    }
}
.ourRowColumnItemText {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.3;
    text-align: center;

    color: #000000;
    margin: 0 auto;
    @media (max-width: $md1+px) {
        font-size: 18px;
    }
    @media (max-width: $md4+px) {
        font-size: 16px;
    }
    @media (max-width: $md5+px) {
        font-size: 12px;
    }
}
