@import "../../../styles/var";

.considerableContainer {
  margin-top: 300px;

  @media (max-width: $md4+px) {
    overflow-x: visible;
    position: relative;
    z-index: 30;
    margin-top: 220px;
  }
  @media (max-width: $md5+px) {
    margin-top: 50px;
  }
  .considerableTitle {
    font-size: 50px;
    font-weight: 800;
    color: #36336c;
    text-align: center;
    @media (max-width: $md4+px) {
      font-size: 30px;
      margin-bottom: 30px;
    }
  }
  .considerableWrap {
    @media (max-width: $md3+px) {
      overflow-x: auto;
      overflow-y: hidden;
    }

    .considerableRow {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: $md2+px) {
        width: 1352px;
        margin-bottom: 10px;
      }
      @media (max-width: $md4+px) {
        width: auto;
      }
      .cb1 {
        text-align: center;
      }
      .considerableCol3 {
        flex: 0 0 auto;
        width: 25%;
        @media (max-width: $md4+px) {
          width: 50%;
        }
        @media (max-width: $md6+px) {
          width: 100%;
        }
        .considerableItem {
          position: relative;
          min-height: 390px;
          @media (max-width: $md4+px) {
            min-height: auto;
            height: auto;
          }
          @media (max-width: $md5+px) {
            min-height: auto;
            height: auto;
          }
          .considerableItemBehind {
            max-width: 265px;
            min-height: 290px;
            background-color: #2d2c56;
            padding: 20px 15px;
            border-radius: 6px 38px 0px 0px;
            box-shadow: -9.61708px 6.41139px 25.6455px rgba(0, 0, 0, 0.3),
              inset -3.20569px 6.41139px 64.1139px rgba(178, 180, 255, 0.4),
              inset -0.641139px 1.28228px 1.28228px #d1d6f8;
            transform: translateY(100px);
            transition: all 0.3s ease-in-out;
            @media (max-width: $md4+px) {
              min-height: auto;
              background-color: transparent;
              box-shadow: none;
              max-width: 100%;
              transform: translateY(0px);
              padding: 25px 25px;
            }
            @media (max-width: $md5+px) {
              min-height: auto;
              background-color: transparent;
              box-shadow: none;
              max-width: 100%;
              transform: translateY(0px);
              padding: 10px 10px;
            }
            &:hover {
              transform: translateY(0);
            }
            h5 {
              display: none;
              @media (max-width: $md3+px) {
                display: block;
                font-size: 13px;
                font-weight: 700;
                margin-bottom: 5px;
                color: #fff;
              }
              @media (max-width: $md4+px) {
                color: #000;
              }
            }
            p {
              font-size: 11px;
              color: #fff;
              line-height: 19px;
              @media (max-width: $md4+px) {
                font-size: 13px;
                color: #000;
              }
            }
          }

          .considerableItemFront {
            height: 190px;
            @media (max-width: $md4+px) {
              display: none;
            }
            &:after {
              content: "";
              position: absolute;
              bottom: 0;
              left: -85px;
              right: 0;
              width: 450px;
              height: 290px;
              background-image: url("../../../assets/img/Main/considerable-img.png");
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center bottom;
            }
            p {
              font-size: 20px;
              font-weight: 800;
              color: #36336c;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              margin-right: 130px;
              padding-bottom: 30px;
              position: relative;
              z-index: 999;
              @media (max-width: $md2+px) {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
