@import '../../../styles/var';

.tradingPlatformTitle {
  font-size: 50px;
  font-weight: bold;
  color: #36336C;
  text-align: center;
  margin: 100px auto;
  @media (max-width: $md4+px) {
    font-size: 25px;
    padding: 0 45px;
    margin: 70px auto 30px;
  }
}

.tradingPlatformRow {
  display: flex;
  flex-wrap: wrap;
  .tradingPlatformCol6 {
    flex: 0 0 auto;
    width: 50%;
    @media (max-width: $md4+px) {
      width: 100%;
    }
    .tradingPlatformList {
      .tradingPlatformItem {
        padding-left: 57px;
        position: relative;
        @media (max-width: $md4+px) {
          padding-left: 40px;
        }
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 27px;
          height: 27px;
          box-shadow: inset -0.901293px 0.901293px 1.80259px #8181C9;
          filter: drop-shadow(-2.70388px 3.60517px 9.01293px rgba(0, 0, 0, 0.5));
          background: #2A2D54;
          border-radius: 50%;
          @media (max-width: $md4+px) {
            width: 20px;
            height: 20px;
          }
        }
        .tradingPlatformItemTitle {
          font-size: 30px;
          font-weight: 600;
          color: #36336C;
          margin-bottom: 10px;
          @media (max-width: $md4+px) {
            font-size: 14px;
          }
        }
        .tradingPlatformItemText {
          font-size: 20px;
          font-weight: 300;
          max-width: 465px;
          width: 100%;
          margin-bottom: 25px;
          @media (max-width: $md4+px) {
            font-size: 14px;
          }
        }
      }
    }
    .tradingPlatformLinkWrap {
      margin-top: 70px;
      @media(max-width: $md5+px) {
        justify-content: center;
        display: flex;
      }
    }
    &.tradingPlatformImgWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: $md4+px) {
        margin-top: 40px;
      }
      .imgFluid {
        width: 100%;
        height: auto;
      }
    }

  }
}
