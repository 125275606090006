@import "../../../styles/_var.scss";

.header {
    position: relative;
}
.headerBody {
    margin-top: 50px;
}
.headerRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headerRowLogo {
    min-width: 150px;
    max-width: 150px;
    position: relative;
    transition: all 0.3s linear;
    z-index: 10;
    @media (max-width: $md1+px) {
        min-width: 110px;
        max-width: 110px;
    }
    @media (max-width: $md3+px) {
        min-width: 100px;
        max-width: 100px;
    }

    img {
        width: 100%;
    }
    &.active {
        @media (max-width: $md5+px) {
            filter: brightness(0) invert(1);
        }
    }
}

.headerRowCenterWrapper {
    @media (max-width: $md4+px) {
        position: absolute;
        z-index: 10;
        top: -50px;
        margin-top: 0px;
        right: -50px;
        pointer-events: none;
        width: 600px;
        height: 650px;

        z-index: 6;
        overflow: auto;
        display: block;
        text-align: center;
        opacity: 0;
        transition: all 0.3s linear;

        &.active {
            pointer-events: visible;
            opacity: 1;
            right: 0;
            top: -50px;
            @media (max-width: $md5+px) {
                top: 0;
            }
        }
    }
    @media (max-width: $md5+px) {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
    }
}
.headerRowCenter {
    width: 700px;
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        width: 500px;
    }
    @media (max-width: $md4+px) {
        width: 100%;
        max-width: 100%;
    }
    a {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #000000;
        @media (max-width: $md3+px) {
            font-size: 16px;
        }
        @media (max-width: $md4+px) {
            text-align: center;
            color: #fff;
            display: block;
            margin-bottom: 30px;
            font-size: 25px;
        }
        position: relative;
        &:active,
        &.active {
            &:after {
                opacity: 1;
            }
        }
        &:after {
            content: "";
            display: "inline-block";
            left: -20%;
            top: -75%;
            position: absolute;
            width: 140%;
            height: 250%;
            box-shadow: inset -4px -4px 4px #ffffff, inset 5px 5px 2px rgba(0, 0, 0, 0.12);
            border-radius: 29px;
            opacity: 0;

            @media (max-width: $md4+px) {
                display: none;
            }
        }
    }
    @media (max-width: $md4+px) {
        display: block;
        width: 100%;
        height: 643px;
        text-align: left;

        padding: 120px 10px 10px 10px;
        background-color: #272856;
        position: relative;
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            z-index: -1;
            height: 200%;
            background-color: #272856;
        }
    }
    @media (max-width: $md5+px) {
        height: auto;
        padding: 140px 18px 20px 18px;
    }
}
.headerRowCenterItem {
}
.headerRowRight {
    display: flex;
    align-items: center;

    z-index: 5;
}
.headerRowRightLocalization {
    position: relative;
    z-index: 10;
    margin-right: 40px;
    margin-left: 60px;

    @media (max-width: $md5+px) {
        margin: 0 20px 0 10px;
    }
    @media (max-width: $md6+px) {
        display: none;
    }
    &.mob {
        display: none;
    }
    @media (max-width: $md6+px) {
        &.mob {
            display: block;
            text-align: center;
            margin: 30px auto 0 auto !important;

            display: flex;
            justify-content: center;
            .headerRowRightLocalizationItems {
                right: 0;
                left: 0;
                margin: auto;
                width: 50px;
                font-size: 20px;
            }
            .headerRowRightLocalizationTitle {
                font-size: 20px;
            }
        }
    }
}
.headerRowRightLocalizationTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    transition: all 0.3s linear;
    color: #21211d;
    @media (max-width: $md3+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
    &.white {
        color: #fff;
        img {
            filter: brightness(0) invert(1);
        }
    }

    &.active {
        img {
            transform: rotate(180deg);
        }
    }
    img {
        transition: all 0.3s linear;
        margin-left: 5px;
    }
}
.headerRowRightLocalizationItems {
    position: absolute;
    left: 2px;
    top: 30px;
    background: #fff;
    color: #000;
    padding: 5px 0;

    p {
        padding: 5px 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    display: none;
    &.active {
        display: block;
    }
}
.headerRowRightSignBtn {
    margin-left: 20px !important;
    @media (max-width: $md4+px) {
        z-index: 10;
    }
    @media (max-width: $md5+px) {
        margin-left: 10px !important;
    }
    & > div {
        width: 145px;
        text-transform: none;
        @media (max-width: $md2+px) {
            width: 100px !important;
        }
    }
    @media (max-width: $md4+px) {
        &.active {
            & > div:after {
                opacity: 0;
            }
        }
        // background-color: red;
        margin-left: 30px !important;
    }
    @media (max-width: $md5+px) {
        display: none;
    }
    &.mob {
        display: none;
        @media (max-width: $md5+px) {
            text-align: center;
            margin: 30px auto 0 auto !important;
            display: flex;
            justify-content: center;
            & > div {
                width: 150px !important;
            }
        }
    }
}
.headerRowRightLogBtn {
    & > div {
        width: 137px;
        text-transform: none;
        @media (max-width: $md2+px) {
            width: 90px !important;
        }
    }
    @media (max-width: $md4+px) {
        &.active {
            & > div:after {
                opacity: 0;
            }
        }
    }
    @media (max-width: $md5+px) {
        display: none;
    }
    &.mob {
        display: none;
        @media (max-width: $md5+px) {
            display: flex;
            justify-content: center;
            text-align: center;
            margin: 30px auto 0 auto !important;
            & > div {
                width: 150px !important;
            }
        }
    }
}

.headerRowRightBurger {
    position: relative;
    height: 23px;
    width: 32px;
    margin-left: 54px;
    z-index: 10;
    display: none;
    cursor: pointer;
    position: relative;

    &:after {
        content: "";
        display: "inline-block";
        left: -10px;
        top: -8px;
        position: absolute;
        width: 50px;
        height: 40px;
    }

    @media (max-width: $md4+px) {
        margin-left: 24px;
        display: block;
    }
    @media (max-width: $md5+px) {
        margin-left: 15px;
    }
    span {
        pointer-events: none;
        position: absolute;
        width: 32px;
        transition: all 0.3s linear;
        height: 3px;
        background-color: #000;
        &:first-child {
            right: 0;
            top: 0;
            width: 20px;
        }
        &:nth-child(2) {
            top: 0;
            bottom: 0;
            right: 0;

            margin: auto;
        }
        &:last-child {
            bottom: 0;
            width: 20px;
            right: 0;
        }
    }
    &.active {
        span {
            background-color: #fff;
            &:first-child {
                left: 0;
                top: 11px;

                margin: auto;
                width: 32px;
                transform: rotate(45deg);
            }
            &:nth-child(2) {
                transform: rotate(-45deg);
                width: 32px;
            }
            &:last-child {
                opacity: 0;
            }
        }
    }
}
