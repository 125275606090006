@import "../../../styles/_var.scss";
.have {
    &.hover {
        .haveBody {
            top: 0;
        }
    }
}
.haveBody {
    margin-bottom: 120px;
    background: url("../../../assets/img/faq/2/1.png") right 0 no-repeat;
    @media (max-width: $md1+px) {
        background: url("../../../assets/img/faq/2/1.png") right center / 800px auto no-repeat;
    }
    @media (max-width: $md3+px) {
        background: url("../../../assets/img/faq/2/1.png") right center / 500px auto no-repeat;
    }
    @media (max-width: $md4+px) {
        background: url("../../../assets/img/faq/2/1.png") center bottom / 500px auto no-repeat;
        padding-bottom: 400px;
        margin-bottom: 30px;
    }
    @media (max-width: $md5+px) {
        padding-bottom: 330px;
        background: url("../../../assets/img/faq/2/1.png") center bottom / 400px auto no-repeat;
        top: 50px;
        margin-bottom: 20px;
    }
    position: relative;
    top: 200px;
    transition: all 1s linear;
}
.haveForm {
    width: 512px;
    @media (max-width: $md5+px) {
        width: 100%;
    }
}
.haveFormTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 40px;
    line-height: 1.1;
    color: #36336c;
    margin-bottom: 15px;

    @media (max-width: $md2+px) {
        font-size: 35px;
    }
    @media (max-width: $md4+px) {
        font-size: 30px;
    }
    @media (max-width: $md5+px) {
        font-size: 25px;
    }
}
.haveFormSubtitle {
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 55px;
    @media (max-width: $md4+px) {
        font-size: 18px;
    }
    @media (max-width: $md5+px) {
        font-size: 16px;
    }
    @media (max-width: $md6+px) {
        font-size: 14px;
    }
}
.haveFormInput {
    input {
        background: linear-gradient(135deg, #d5d7e5 0%, #ebedfd 100%);
        box-shadow: -1px 1px 2px rgba(181, 183, 195, 0.2), 1px -1px 2px rgba(181, 183, 195, 0.2),
            -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 5px 30px rgba(181, 183, 195, 0.25),
            inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(181, 183, 195, 0.5);
        border-radius: 25px;
        height: 40px;
        width: 100%;
        line-height: 40px;
        padding: 0 50px;
        @media (max-width: $md6+px) {
            padding: 0 20px;
        }
        margin-bottom: 25px;
        &::placeholder {
            color: #000;
            opacity: 0.4;
        }
    }
}
.haveFormTextarea {
    margin-bottom: 32px;
    max-width: 100%;
    height: 115px;
    padding: 20px 20px 20px 50px;
    background: linear-gradient(135deg, #d5d7e5 0%, #ebedfd 100%);
    box-shadow: -1px 1px 2px rgba(181, 183, 195, 0.2), 1px -1px 2px rgba(181, 183, 195, 0.2),
        -1px -1px 2px rgba(255, 255, 255, 0.9), 1px 5px 30px rgba(181, 183, 195, 0.25),
        inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(181, 183, 195, 0.5);
    border-radius: 25px;
    @media (max-width: $md6+px) {
        padding: 20px 20px 20px 20px;
    }
    textarea {
        width: 100%;
        height: 100%;
        background: transparent;
        resize: none;
        overflow: auto;
        padding-right: 10px;
        &::-webkit-scrollbar {
            width: 3px; /* ширина всей полосы прокрутки */
            position: relative;
            left: 20px;
        }

        &::-webkit-scrollbar-track {
            background-color: #dcdded;
            border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #8988ad; /* цвет бегунка */
            border-radius: 2px;
        }
    }
}
.haveFormCheckbox {
    display: flex;
    cursor: pointer;

    span {
        display: block;
        min-width: 30px;
        margin-right: 20px;
        height: 30px;
        background: #e2e4f3;
        border: 1px solid #b7b9d5;
        border-radius: 50%;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
        position: relative;
        &:after {
            content: "";
            display: "inline-block";
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
            width: 20px;
            height: 20px;
            background-color: #b990f2;
            border-radius: 50%;
            opacity: 0;
            transition: all 0.3s linear;
        }
    }
    &.active {
        span {
            &:after {
                opacity: 0.3;
            }
        }
    }
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 10px;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 35px;
}

.haveFormSubmit {
    & > div {
        width: 156px;
    }
}
