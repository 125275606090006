@import '../../../styles/var';

.banner {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        display: block;
    }
    .content {
        margin-top: 130px;
        @media (max-width: $md1+px) {
            margin-top: 130px;
        }
        @media (max-width: $md2+px) {
            margin-top: 100px;
        }
        .header {
            font-weight: 800;
            font-size: 70px;
            line-height: 105px;
            color: #36336C;
            width: 730px;
            @media (max-width: $md1+px) {
                font-size: 60px;
                line-height: normal;
                width: 650px;
            }
            @media (max-width: $md2+px) {
                font-size: 50px;
                width: 600px;
            }
            @media (max-width: $md3+px) {
                width: 500px;
            }
            @media (max-width: $md5+px) {
                width: 100%;
            }
            @media (max-width: $md6+px) {
                font-size: 30px;
            }
        }
        .text {
            font-weight: 400;
            font-size: 30px;
            line-height: 45px;
            color: #000000;
            width: 600px;
            margin-top: 10px;
            @media (max-width: $md1+px) {
                font-size: 24px;
                line-height: normal;
                width: 450px;
            }
            @media (max-width: $md2+px) {
                font-size: 22px;;
            }
            @media (max-width: $md5+px) {
                width: 100%;
            }
            @media (max-width: $md6+px) {
                font-size: 20px;
                width: 100%;
                margin-top: 25px;
            }
        }
        .btn {
            margin-top: 70px;
            @media (max-width: $md6+px) {
                margin-top: 50px;
            }
        }
    }
    .imageActive {
        .polygon {
            top: 340px!important;
            left: -100px!important;
        }
        .sphere {
            top: 50px!important;
            right: 110px!important;
        }
        .hemisphere {
            top: 40px!important;
            left: -50px!important;
        }
        .bagel_half {
            top: 23px!important;
            right: 80px!important;
        }
    }
    .image {
        position: relative;
        img {
            transition: all 1s;
        }
        .cow {
            width: 760px;
            padding-top: 20px;
            display: flex;
            margin-left: auto;
            @media (max-width: $md1+px) {
                width: 700px;
            }
            @media (max-width: $md2+px) {
                width: 600px;
                // padding-top: 80px;
            }
            @media (max-width: $md3+px) {
                width: 500px;
                padding-top: 0;
                margin-top: 70px;
            }
            @media (max-width: $md4+px) {
                display: flex;
                margin: 40px auto 0 auto;
            }
            @media (max-width: $md6+px) {
                width: 100%;
                margin-top: 20px;
            }
        }
        .polygon {
            position: absolute;
            top: 320px;
            left: -60px;
            @media(max-width: $md2+px) {
                width: 90px;
                top: 300px;
                left: -20px;
            }
            @media(max-width: $md3+px) {
                display: none;
            }
        }
        .sphere {
            position: absolute;
            top: 100px;
            right: 150px;
            @media(max-width: $md2+px) {
                width: 50px;
                top: 70px;
                right: 130px;
            }
            @media(max-width: $md3+px) {
                display: none;
            }
        }
        .hemisphere {
            position: absolute;
            top: 50px;
            left: -30px;
            @media(max-width: $md2+px) {
                width: 80px;
                left: 0;
            }
            @media(max-width: $md3+px) {
                display: none;
            }
        }
        .bagel_half {
            position: absolute;
            top: 40px;
            right: 80px;
            transform: rotate(-55deg);
            @media(max-width: $md2+px) {
                width: 70px;
            }
            @media(max-width: $md3+px) {
                display: none;
            }
        }
    }
}