@import "../../../styles/_var.scss";
.forex {
}
.forexBody {
}
.forexRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md4+px) {
        flex-wrap: wrap;
    }
}
.forexRowLeft {
    padding-top: 120px;
    @media (max-width: $md1+px) {
        padding-top: 100px;
        margin-bottom: 40px;
    }
    @media (max-width: $md3+px) {
        padding-top: 50px;
    }
    @media (max-width: $md4+px) {
        padding-top: 100px;
    }
}
.forexRowLeftTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-size: 70px;
    line-height: 1.4;
    color: #36336c;
    margin-bottom: 30px;
    @media (max-width: $md1+px) {
        font-size: 60px;
    }
    @media (max-width: $md2+px) {
        font-size: 50px;
    }
    @media (max-width: $md4+px) {
        font-size: 45px;
    }
    @media (max-width: $md5+px) {
        font-size: 40px;
    }
}
.forexRowLeftSubtitle {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 30px;
    line-height: 1.3;
    color: #000000;
    margin-bottom: 70px;
    @media (max-width: $md1+px) {
        margin-bottom: 50px;
        font-size: 25px;
    }
}
.forexRowRight {
    flex: 0 1 850px;
    @media (max-width: $md4+px) {
        margin: 0 -10px;
    }
    img {
        width: 100%;
    }
}
