@import '../../../styles/var';

.spoilersWrapper {
    width: 1220px;
    margin: 0 auto;
    @media (max-width: 1300px) {
        width: 100%;
    }
}
.spoilers {
    &.hover {
        .spoilersBody {
            top: 0px;
        }
    }
}
.spoilersBody {
    background: url("../../../assets/img/about/2/2.png") right -150px no-repeat;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    top: 250px;
    transition: all 1s linear;
    @media(max-width: $md3+px) {
        top: 0;
    }
}
